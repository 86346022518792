import "scroll-behavior-polyfill";
import SmoothScroll from "smooth-scroll";

// navBtn
import NavBtn from "./class/NavBtn";
// 医療従事者チェック
import HealthcareworkerCheck from "./class/HealthcareworkerCheck";
// //ヘッダー「ENTRY」ボタン動作
// import EntryBtn from "./class/EntryBtn";


// fadeIn
function fadeIn(elm, ms) {
  elm.style.opacity = 0; // 透過度０
  elm.style.display = "block";
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 1;
  }, 1); // 0.001秒後に transition開始(透過度１にする）
}
// fadeOut
function fadeOut(elm, ms) {
  elm.style.opacity = 1; // 透過度1
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 0;
  }, 1); // 0.001秒後に transition開始（透過度0にする）
  setTimeout(function () {
    elm.style.display = "none";
  }, ms + 10); // 1.01秒後に完全に消す。
}

//ページ内慣性スクロール
var scroll = new SmoothScroll('a[href*="#"]', {
  header: '[data-scroll-header]'
});




window.addEventListener("DOMContentLoaded", () => {
  new NavBtn(fadeIn,fadeOut); 
  new HealthcareworkerCheck(fadeIn,fadeOut);
  // new EntryBtn(); // ヘッダー「ENTRY」ボタン動作
});
