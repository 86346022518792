export default class HealthcareworkerCheck {
    constructor(fadeIn,fadeOut) {
        const target = document.querySelector("#healthcareworker");
        const yes = target.querySelector(".yes");
        const trigger = document.querySelector(".healthcareworkerCheck");
        trigger.addEventListener("click",function(e){
            e.preventDefault();
            fadeIn(target,100);
        })
        yes.addEventListener("click",function(){
            fadeOut(target,100);
        })
    }
}