export default class NavBtn {
    constructor(fadeIn,fadeOut) {

        const header = document.querySelector("header");
        const nemuBtn = document.querySelector(".nemuBtn");
        // console.log(nemuBtn)
        let navFlg = false;
        nemuBtn.addEventListener("click", function () {
            if(navFlg === false){
                nemuBtn.classList.add("act");
                fadeIn(header, 100);
                navFlg = true;
            }else{
                nemuBtn.classList.remove("act");
                fadeOut(header, 100);
                navFlg = false;
            }
        })
    }
}